import React from "react";
import {Twitter, Linkedin, Github} from 'react-bootstrap-icons';


function Socials() {
  return (
    <div className="socials" style={{display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '30px', marginLeft: '30px'}}>
      <a href="https://github.com/lroe34" target="_blank" rel="noopener noreferrer">
      <Github color="gray" size={25}/>
      </a>
      <a
        href="https://www.linkedin.com/in/luke-m-roe"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Linkedin color="gray" size={25}/>
      </a>
      <a
        href="https://twitter.com/lukemroe"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Twitter color="gray" size={25}/>
      </a>
    </div>
  );
}

export default Socials;
