import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';
import reportWebVitals from './reportWebVitals';
import Timeline from './timeline';
import Socials from './socials';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="main" >
    <b><h1>Luke Roe</h1></b>
    <Socials/>
    <b><h1>Projects</h1></b>
    <Timeline/>
    </div>

  </React.StrictMode>
);

reportWebVitals();
