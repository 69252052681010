import "./styles.css";
import "./timeline.css";
import React, { useState, useEffect, useRef } from 'react';
import TimelineItem from './timeline_item';
import sail from './images/sail.png';
import inbetween_dark from './images/inbetween_dark.png';
import inbetween_light from './images/inbetween_light.png';
import inbetween_categories from './images/inbetween_categories.png';
import inbetween_logo from './images/inbetween_logo.svg';
import sail_logo from './images/sail_logo.svg';
import {ArrowUpRight, ArrowLeft} from 'react-bootstrap-icons';
import sail_page from './images/sail_page.png';
import quartinos from './images/quartinos.png';
import itinerary from './images/itinerary.png';

export default function Timeline() {
    const navRef = useRef(null);
    const [supportsAnchorPos, setSupportsAnchorPos] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState("");
    const [selectedDescription, setSelectedDescription] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [blur, setBlur] = useState(false);
    const [selectedGithub, setSelectedGithub] = useState("");
    const [selectedWebsite, setSelectedWebsite] = useState("");
    const [selectedText, setSelectedText] = useState("");
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
    
        // Set initial value
        handleResize();
    
        // Setup event listener
        window.addEventListener('resize', handleResize);
    
        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const timelineData = [
        { id: "inbetween", title: "inbetween", description: "travel, mobile app", date: "2024 - present", text: "inbetween is a travel app that allows you to plan your next vacation by suggesting places to visit based on your interests and the weather in the area. It also allows you to share your own photos and experiences with friends and family.", images: [inbetween_dark, inbetween_categories, inbetween_light], icon: inbetween_logo, company: "SAIL LLC", github: "", website: "https://inbetweenplaces.app"},
        { id: "sail", title: "Sail", description: "travel, social media, mobile app, AI/ML", date: "2023 - present", images: [sail_page, quartinos, itinerary], icon: sail_logo, company: "Sail LLC", github: "", website: "https://sailvacations.app"},
        { id: "ecs", title: "Excercise Recommender", description: "AI/ML, python", date: "2023", images: [], icon: null, company: "", github: "https://github.com/lroe34/exercise-recommender", website: ""},
        { id: "data", title: "Database Terminal", description: "python, SQL", date: "2023", images: [], icon: null, company: "", github: "https://github.com/lroe34/database-terminal", website: ""},
        { id: "defi", title: "Decentralized Options", description: "defi, ETH, smart contracts", date: "2022", images: [], icon: null, company: "", github: "https://github.com/lroe34/research-project-app", website: ""},
        { id: "bin", title: "Binomial Distribution Calculator", description: "python, statistics", date: "2022", images: [], icon: null, company: "", github: "https://github.com/lroe34/binomial-distribution-calculator", website: ""},
    ];
    
    useEffect(() => {
      setSupportsAnchorPos("anchorName" in document.documentElement.style);
      const nav = navRef.current;
      const anchors = nav.querySelectorAll('a');
  
      const sync = () => {
        for (let i = 0; i < anchors.length; i++) {
          anchors[i].style.setProperty('view-transition-name', `item-${i + 1}`);
          if (!supportsAnchorPos) {
            const bounds = anchors[i].getBoundingClientRect();
            nav.style.setProperty(`--item-${i + 1}-x`, bounds.left);
            nav.style.setProperty(`--item-${i + 1}-y`, bounds.top);
            nav.style.setProperty(`--item-${i + 1}-width`, bounds.width);
            nav.style.setProperty(`--item-${i + 1}-height`, bounds.height);
          }
        }
      };
  
      const calibrate = sync;
      if (!supportsAnchorPos) {
        document.documentElement.dataset.noAnchor = true;
        calibrate();
        window.addEventListener('resize', calibrate);
      }
  
      return () => {
        window.removeEventListener('resize', calibrate);
      };
    }, [supportsAnchorPos]);
  
    const handlePointerEnter = (index, title) => {
        
      const nav = navRef.current;
      if (!supportsAnchorPos) {
        nav.style.setProperty('--item-active-x', `var(--item-${index + 1}-x)`);
        nav.style.setProperty('--item-active-y', `var(--item-${index + 1}-y)`);
        nav.style.setProperty('--item-active-width', `var(--item-${index + 1}-width)`);
        nav.style.setProperty('--item-active-height', `var(--item-${index + 1}-height)`);
      }
    };


    const handleClicked = (title) => {
        if (title === selectedTitle) return;
        if (isMobile) {
            setSelectedTitle(title);
            timelineData.forEach(item => {
            if (item.title === title) {
                setSelectedDescription(item.description);
                setSelectedDate(item.date);
                setSelectedIcon(item.icon);
                setSelectedImages(item.images);
                setSelectedCompany(item.company);
                setSelectedGithub(item.github);
                setSelectedWebsite(item.website);
                setSelectedText(item.text);
                    }
                });
        } else {
        setBlur(true)
        // set title, description and date after 150ms
        setTimeout(() => {
            setSelectedTitle(title);
            timelineData.forEach(item => {
            if (item.title === title) {
                setSelectedDescription(item.description);
                setSelectedDate(item.date);
                setSelectedIcon(item.icon);
                setSelectedImages(item.images);
                setSelectedCompany(item.company);
                setSelectedGithub(item.github);
                setSelectedWebsite(item.website);
                setSelectedText(item.text);
                    }
                });
            }
        , 150);}
        setTimeout(() => setBlur(false), 150);
    }


    return (
        <div className="container" >
            {selectedTitle === "" || !isMobile ? 
                <div ref={navRef}>
                    <nav data-magnetic>
                        <ul>
                            {timelineData.map((item, index) => (
                                <li key={item.id}>
                                    <a href={`#${item.id}`} id={item.id}
                                    onMouseEnter={() => handlePointerEnter(index, item.title)}
                                    onClick={() => handleClicked(item.title)}>
                                        <TimelineItem title={item.title} description={item.description} date={item.date} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                : 
                <div>
                    <ArrowLeft onClick={() => setSelectedTitle("")} style={{cursor: 'pointer'}}/>
                </div>
            }
        <div className="selected-container" style={{opacity: selectedTitle ? 1 : 0}}>
            <div className={blur ? "blur-effect" : ""} style={{width: "100%", height: "100%"}}>
                <div className="selected-item">
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                        {selectedIcon && <img src={selectedIcon} alt="Selected Icon" height="30px" />}
                        <h1>{selectedTitle}</h1>
                        {selectedCompany && <p style={{fontSize: '12px', color: 'gray', backgroundColor: 'white', padding: '2px 7px', borderRadius: '10px', border: '1px solid lightgray'}}>{selectedCompany}</p>}
                    </div>
                    <b><p>{selectedDate}</p></b>
                    
                </div>
                <div className="selected-description" style={{color: 'gray'}}>
                    <p>{selectedDescription}</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row'}}>
                    <div style={{width: '50%', marginLeft: '30px'}}>
                        <p style={{fontSize: '10pt', color: 'gray'}}>{selectedText}</p>
                    </div>
                {selectedImages.length > 0 && (
                    <div className="selected-images" style={{position: 'relative', height: '200px', width: '250px', display: 'flex', justifyContent: 'right', alignItems: 'center', paddingRight: '50px'}}>
                        {selectedImages.map((image, index) => (
                            <img key={index} src={image} style={{
                                width: "100px", 
                                position: 'absolute', 
                                top: 0,
                                transition: 'transform 0.5s',
                                transform: `rotate(${(index - (selectedImages.length / 2)) * 10}deg) translateX(${index * 20 - (selectedImages.length * 10)}px)`,
                                filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.2))',

                                transformOrigin: 'bottom center',
                            }} alt={`Selected Image ${index + 1}`} />
                        ))}
                    </div>
                )}
                </div>
                
                <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '10px', marginLeft: '30px'}}>
                    {selectedGithub && <a style={{fontSize: '12px', color: 'gray', backgroundColor: 'white', padding: '2px 9px', borderRadius: '10px', border: '1px solid lightgray', display: 'flex', alignItems: 'center', gap: '5px', margin: '10px'}} href={selectedGithub} target="_blank" rel="noreferrer">{selectedGithub} <ArrowUpRight /></a>}
                    {selectedWebsite && <a style={{fontSize: '12px', color: 'gray', backgroundColor: 'white', padding: '2px 9px', borderRadius: '10px', border: '1px solid lightgray', display: 'flex', alignItems: 'center', gap: '5px', margin: '10px'}} href={selectedWebsite} target="_blank" rel="noreferrer">{selectedWebsite} <ArrowUpRight /></a>}
                </div>
            </div>

        </div>
        </div>
      ); 


}