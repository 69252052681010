import "./timeline.css";

export default function TimelineItem(props) {
    return (
        <div className="timeline-item">
            <div className="timeline-content">
                <div className="title">
                    <b>{props.title}</b>
                    <p style={{fontSize: "12px", paddingLeft: "30px"}}>{props.date}</p>
                </div>
                <p style={{fontSize: "12px"}}>{props.description}</p>
            </div>
        </div>
    );
}

